import React from "react";
import tokAbi from "../../constants/abi.json";
import tokAddress from "../../constants/address";
import { ethers ,BigNumber} from "ethers";
import {
  useAccount,
  useConnect,
  useContractRead,
  usePrepareContractWrite,
  useContractWrite,
} from "wagmi";
import {toast} from 'react-toastify'

const BuyToken = ({ bnb }) => {
  
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();

  

  const { write } = useContractWrite({
    mode: "recklesslyUnprepared",
    address: tokAddress.tokenAddres,
    abi: tokAbi,
    functionName: "buyTokens",
    overrides: {
      from: address,
      value: bnb  && isFinite(bnb) && bnb>0? ethers.utils.parseEther(`${bnb}`):"0",
    },
    onError(error) {
      console.log('Error', error?.reason)

      toast(error.data && error.data.message ? error.data.message : error.reason || "An error occurred.", {
        // position: "top-right",
        // autoClose: 5000,
        // hideProgressBar: false,
        // closeOnClick: true,
        // pauseOnHover: true,
        // draggable: true,
        // progress: undefined,
      })
      
    },
  });
  return (
    <button type="submit" className="mt-4 btn"  onClick={() =>
      isConnected
        ? write?.()
        : toast("Connect Wallet Before", {
            // position: "top-right",
            // autoClose: 5000,
            // hideProgressBar: false,
            // closeOnClick: true,
            // pauseOnHover: true,
            // draggable: true,
            // progress: undefined,
          })
    }>
      Buy
    </button>
  );
};

export default BuyToken;
