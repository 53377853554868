import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { use } from "i18next";

const SalesTabContent = (props) => {
  const {t}=useTranslation()
  return (
    <div
      className={cn("tab-pane fade", props.className)}
      id={props.id}
      role="tabpanel"
      aria-labelledby={props.ariaLabel}
    >
      <div className="chart-content-inner">
        <h2 className="title">{props.title} <span style={{color:"#ffa5d6"}}>Token</span></h2>
        <div className="d-flex justify-content-between mb-4">
  <div className="bg-pink p-2 text-center">
    <h5 className="mb-0">Symbol</h5>
    <p className="m-0">JOUD</p>
  </div>
  <div className="bg-pink p-2 text-center">
    <h5 className="mb-0"> Supply</h5>
    <p className="m-0">1,000,000,000</p>
  </div>
  <div className="bg-pink p-2 text-center">
    <h5 className="mb-0"> Type</h5>
    <p className="m-0">BEP-20</p>
  </div>
</div>
<h3>{t('sale.utility')}</h3>

        <p>{props.description}</p>
        {/* <Link to={props.link} className="btn">
          Buy Now
        </Link> */}
      </div>
    </div>
  );
};

export default SalesTabContent;
