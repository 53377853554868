import React from "react";
import img01 from "../../assets/img/images/about_img01.png";
import img02 from "../../assets/img/images/about_img02.png";
import { useTranslation } from "react-i18next";

const WhoWeAre = (props) => {
  const {t}=useTranslation()
  return (
    <section id="about" className="roadmap-area pt-10 pb-10">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
              <img src={img02} alt="" className="img-two" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">{t('whoWeAre.whoWe')}</span>
                <h2 className="title"
                dangerouslySetInnerHTML={{__html: t('whoWeAre.title')}}
                >
                  {/* The World’s <span>1st ICO</span> Platform That Offers Rewards */}
                </h2>
              </div>
              <p>
                { t('whoWeAre.desc')}
              </p>
              {/* <Link to="/" className="btn">
                Purchase Tokens
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
