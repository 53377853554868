import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ErrorMessage } from "formik";
import TextError from "./TextError";
import tokAbi from "../../constants/abi.json";
import tokAddress from "../../constants/address";
import { ethers } from "ethers";
import {
  useContractRead,
  usePrepareContractWrite,
  useContractWrite,
} from "wagmi";
import { useEffect, useState } from "react";
import TokenAmount from "./TokenAmount";
import BnBprice from "./BnBprice";
import BuyToken from "./BuyToken";
import { BigNumber } from "ethers";

const FormTok = () => {
  const [token, setToken] = useState("");
  const [tokenBuy, setTokenBuy] = useState("");
  const { data, isError, isLoading } = useContractRead({
    address: tokAddress.tokenAddres,
    abi: tokAbi,
    functionName: "getBnbForToken",
    args: [ethers.utils.parseEther("1")?.toString()],
  });
  const {
    data: price,
    isError: Error2,
    isLoading: Loading2,
  } = useContractRead({
    address: tokAddress.tokenAddres,
    abi: tokAbi,
    functionName: "getLatestPrice",
  });

  useEffect(() => {
    setToken(data?.toString() ?? {});
  }, [data]);

  const initialValues = {
    bnb: parseFloat(
      (
        0.05 /
        parseFloat(ethers.utils.formatEther(price ?? BigNumber.from("0")))
      ).toFixed(6) + 2
    ).toFixed(6)

    ,
    token: 1,
  };
  // useEffect({

  // },[data])

  const validationSchema = Yup.object({
    bnb: Yup.number().required("Required"),
    token: Yup.number().required("Required").max(1000000000000),
  });

  const onSubmit = async (values) => { };


  return (
    <div className="p-8">
      {/* {data && data} */}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form className="d-flex flex-column  gutter-4 justify-content-center align-items-center">
              <div>
                {" "}
                <BnBprice value={price} />
              </div>

              
              <div className="form-group mt-4">
                <label htmlFor="tok" style={{ color: "pink" }}>
                  Token
                </label>
                <Field
                  id="token"
                  name="token"
                  className={` form-control  `}
                  type="number"
                  value={formik.values.token}
                  max='1000000000000000000'
                  onChange={(e) => {

                    

                    console.log("e.target.value:", e.target.value);
                    console.log("price:", price);

                    const priceInEther = parseFloat(ethers.utils.formatEther(price ?? BigNumber.from("0")));

                  if(e.target.value>100000000000000000000){
                    return
                  }

                    if (price) {
                      formik.setFieldValue('bnb', parseFloat(e.target.value * ((0.05 / priceInEther).toFixed(6) + 2)).toFixed(6))
                    }
                    formik.setFieldValue('token', e.target.value)

                  }}
                />
                <ErrorMessage component={TextError} name="token" />
              </div>
              <div className="form-group mt-4">
                <label htmlFor="bnb " style={{ color: "pink" }}>
                  BNB
                </label>
                <Field
                readOnly
                  id="bnb"
                  // type={typePass ? "text" : "password"}
                  type="number"
                  name="bnb"
                  className={` form-control  `}
                  
                // onChagne={()=>(setToken(formik.values.bnb))}
                />
                <ErrorMessage component={TextError} name="bnb" />
              </div>
              <div>
                {/* {formik.values.bnb && <TokenAmount token={formik.values.bnb} />} */}

               
              </div>
              <div className="mt-4">
                {/* <label htmlFor="tok" style={{color:"pink"}}>Tokens</label> */}
                {/* <Field
              id="tok"
              // type={typePass ? "text" : "password"}
              name="tok"
              className={`  form-control  `}
            />
            <ErrorMessage component={TextError} name="name" /> */}
              </div>

              {/* <button type="submit" className="mt-4 btn" onClick={()=>{}}>Buy</button> */}
              <BuyToken bnb={formik.values.bnb} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default FormTok;
