import React from 'react'
import tokAbi from "../../constants/abi.json";
import tokAddress from "../../constants/address";
import { ethers } from "ethers";
import { useContractRead,usePrepareContractWrite,useContractWrite } from "wagmi";
import { useEffect,useState } from "react";
import { BigNumber } from 'ethers';

const BnBprice = ({value}) => {
    // const {data, isError:Error2, isLoading:Loading2 } = useContractRead({
    //     address: tokAddress.tokenAddres,
    //     abi: tokAbi,
    //     functionName: 'getLatestPrice',
    //     // args: [token],
    //   })
    //   console.log(value)
  return (
    <div>{
        value && 
        <div>
             <div>1BNB: {ethers.utils.formatEther(value??BigNumber.from("0"))}</div>
             <div>1JOUD: {parseFloat(
      (
        0.05 /
        parseFloat(ethers.utils.formatEther(value ?? BigNumber.from("0")))
      ).toFixed(6) + 3
    ).toFixed(6)}</div>
        </div>
       
        }</div>
  )
}

export default BnBprice