import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className=""
    >
      <Modal.Header closeButton className="modal-bg">
        <Modal.Title id="contained-modal-title-vcenter">
          How To Buy JOUD
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-bg">
        {/* <h4>Centered Modal</h4> */}
        <div className="text-white">
        <ol>
  <li>
    <p className="" style={{color:"pink",fontWeight:"bold"}}>Step:1 Ensure you have one of the supported wallets by Wallet Connect.</p>
    <ul>
      <li>For desktop, we recommend using MetaMask browser plugin for Chrome or Firefox.</li>
      <li>For mobile, we recommend using Trust Wallet and connecting through the in-built browser.</li>
    </ul>
  </li>
  <li>
    <p className="" style={{color:"pink",fontWeight:"bold"}}>Step:2 Click "Connect Wallet" and select the appropriate option.</p>
    <ul>
      <li>For mobile wallet apps, select "Wallet Connect".</li>
      <li>You will then have 3 options:</li>
      <ol type="a">
        <li>Scan QR Code: Use your mobile wallet to scan the QR code displayed on the website or desktop application.</li>
        <li>Copy WalletConnect URI: Copy the URI provided and paste it into your wallet app.</li>
        <li>Open in WalletConnect: If you are using a mobile wallet app, you can simply click "Open in WalletConnect" and it will automatically connect to the website or desktop application.</li>
      </ol>
    </ul>
  </li>
</ol>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-bg">
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function Buy() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button
        as="div"
        className="btn"
        style={{
          borderColor: "white",
          backgroundColor: "black",
          color: "",
          width: "261px",
        }}
        onClick={() => setModalShow(true)}
      >
        How to Buy
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

// render(<App />);
