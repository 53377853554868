import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import Form from "./FormTok";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className=""
    >
      <Modal.Header closeButton className="modal-bg">
        <Modal.Title id="contained-modal-title-vcenter">
          Buy JOUD
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-bg">
        {/* <h4>Centered Modal</h4> */}
        <div className="text-white">
       <Form />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-bg">
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function BuyForm() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button
        as="div"
        className="btn"
        style={{
          borderColor: "white",
          backgroundColor: "black",
          color: "",
          width: "261px",
        }}
        onClick={() => setModalShow(true)}
      >
        Buy Now
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

// render(<App />);
