import React from "react";
import RoadmapItem from "./RoadmapItem";
import { useTranslation } from "react-i18next";

const Roadmap = () => {

  const { t } = useTranslation()
  const temp_road = t("road", {
    returnObjects: true
  })
  console.log(temp_road)
  const roadmap_items = temp_road.roadMapItems;



  return (
    <section id="roadmap" className="md:pb-130">
      <div className="container custom-container-two">
        <div className="row ">
          <div className="">
            <div className="section-title text-center mb-60">
              <span className="sub-title">{temp_road.subtitle}</span>
              <h2 className="title" dangerouslySetInnerHTML={{ __html: temp_road.title }}>
                {/* JOUD <span>Taxi</span>  Strategy and <br /> Project <span>Plan</span> */}
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="bt-roadmap_x bt-roadmap-scrollbar"
              // style={{ overflow: "auto" }}
            >
              <div className="bt-roadmap-wrap">
                {roadmap_items.map((x, index) => (
                  <RoadmapItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
