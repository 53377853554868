import "@rainbow-me/rainbowkit/styles.css";
import {
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
  trustWallet,
  metaMaskWallet,
} from '@rainbow-me/rainbowkit/wallets';

import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet, polygon, optimism, arbitrum,bscTestnet,bsc } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';



export const { provider, chains } = configureChains(
  [bsc],
  [
    // alchemyProvider({apiKey:""}),
    publicProvider(),
  ]
);
const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      // metaMaskWallet,
      metaMaskWallet({ chains }),
      injectedWallet({ chains }),
      rainbowWallet({ chains }),
      // walletConnectWallet({ chains }),
      trustWallet({ chains }),
    ],
  },
]);

// const { connectors } = getDefaultWallets({
//   appName: "JOUD Token",
//   chains,
// });

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

export function Web3ConnectorConfig({ children }) {
  
  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          theme={darkTheme({
            // accentColor: "#000000",
            // accentColorForeground: "white",
            borderRadius: "large",
            overlayBlur: "large",
            fontStack: "rounded",
          })}
          coolMode
          chains={chains}
        >
         
            {children}
          {/* </ContractContext.Provider> */}
        </RainbowKitProvider>
      </WagmiConfig>
     
      
    </>
  );
}