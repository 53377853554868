import React from "react";
import shape01 from "../../assets/img/banner/banner_shape01.png";
import shape02 from "../../assets/img/banner/banner_shape02.png";
import shape03 from "../../assets/img/banner/banner_shape03.png";
import banner from "../../assets/img/banner/banner-bg.png";
import { useTranslation, Trans } from "react-i18next";
import { ConnectButton } from "@rainbow-me/rainbowkit";


const Banner = (props) => {
  const { t } = useTranslation();
  return (
    // <section className=" banner-bg banner-area">
    //   <div className="banner-shape-wrap">
    //     <img src={shape01} alt="" className="img-one" />
    //     <img src={shape02} alt="" className="img-two" />
    //     <img src={shape03} alt="" className="img-three" />
    //   </div>

    //   <div className="container">
    //     <div className="row justify-content-center">
    //       <div className="col-lg-10">
    //         <div className="banner-content text-center">
    //           {/* <img src={fireIcon} alt="" /> */}
    //           <h2 className="title" dangerouslySetInnerHTML={{ __html: t('bannerTitle') }}>

    //           </h2>
    //         </div>



    //         <div className="text-center ">


    //           <h4 className="title ">
    //             {t('bannerSub')}

    //           </h4>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="row justify-content-center " style={{ marginTop: "30px", marginBottom: "30px" }}>
    //       <div className="col-xl-10">
            // <div className="banner-countdown-wrap text-center">
            //   {/* <h2 className="title">ICO Will Start In..</h2> */}
            //   <a className={"header-btn"} href="/white-paper.pdf" download>
            //     <span to="#" className="btn" style={{ borderColor: "white" }}>
            //       {t('downloadWhitePaper')}
            //     </span>
            //   </a>

            //   {/* <CountDownOne /> */}
            // </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <div className="container  ">
      <div className="row my-5 ">
        <div className="col-lg-6 d-flex flex-column  pt-2 pt-lg-0 order-2 order-lg-1 justify-content-md-center section-title" data-aos="fade-up" data-aos-delay="200">
        <h2 className="text-center text-md-start title" dangerouslySetInnerHTML={{ __html: t('bannerTitle') }}/>  
        <h4 className="text-center text-md-start mt-5 ">
               {t('bannerSub')}

             </h4>  
             
                 
          {/* <div className="d-flex justify-content-center banner-countdown-wrap text-center justify-content-lg-start">
            <div>
            <a className={"header-btn"} href="/white-paper.pdf" download>
                <span to="#" className="btn" style={{ borderColor: "white" }}>
                  {t('downloadWhitePaper')}
                </span>
              </a>
           
            <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video"><i className="bi bi-play-circle"></i><span>Watch Video</span></a>
          </div>
            </div> */}
            <div className="row  " style={{ marginTop: "30px", marginBottom: "30px" }}>
          <div className="col-xl-10">
            <div className="banner-countdown-wrap justify-content-center justify-content-md-start d-flex  ">
              {/* <h2 className="title">ICO Will Start In..</h2> */}
              <a className={"header-btn text-center text-md-start"} href="/white-paper.pdf" download>
                <span to="#" className="btn" style={{ borderColor: "white",backgroundColor:"black", color:"" }}>
                  {t('downloadWhitePaper')}
                </span>
              </a>
             

              

              {/* <CountDownOne /> */}
            </div>
          </div>
        </div>
         
           
          
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
          <img src={banner} className="img-fluid animated" alt=""/>
        </div>
      </div>
    </div>
  );
};

export default Banner;
