import React from "react";
// import chartImg from "../../assets/img/images/chart.png";
import chartImg from "../../assets/img/images/joud-tok.png"
import SalesTabButton from "./SalesTabButton";
import SalesTabContent from "./SalesTabContent";
import { useTranslation } from "react-i18next";
import { BsChevronRight } from "react-icons/bs";

const Sales = () => {
  const chart_info_list = [
    "IDO Sale: 40%",
    "Business Development: 30%",
    "Team: 20%",
    "Community Development: 10%",

  ];
  const { t } = useTranslation()
  const sale = t('sale', {
    returnObjects: true
  });


  return (
    <section id="sales" className=" chart-bg ">
      <div className="container">

        <div className="chart-inner">
          <div className="section-title text-center mb-60">
            {/* <span className="sub-title">OUr Roadmap</span> */}
            <h2 className="title">
              {sale.title}
            </h2>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-10 order-0 order-lg-2" >
              <div className="chart-wrap wow fadeInRight" data-wow-delay=".2s" style={{ background: 'linear-gradient(0deg, hsl(0deg 0% 100%) 0%, hsl(348deg 86% 98%) 1%, hsl(348deg 84% 96%) 4%, hsl(348deg 83% 94%) 7%, hsl(348deg 81% 92%) 11%, hsl(347deg 80% 90%) 16%, hsl(347deg 78% 88%) 23%, hsl(347deg 77% 86%) 33%, hsl(346deg 75% 84%) 47%, hsl(346deg 74% 82%) 81%)' }}>
                <img src={chartImg} alt="" width="250px"/>
                <ul>
                  {chart_info_list.map((x, index) => (
                    <li key={index} className="text-dark">{x}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-10">
              <div
                className="chart-content wow fadeInLeft"
                data-wow-delay=".2s"
              >
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <SalesTabButton
                    // title="Funding Allocation"
                    // className="active"
                    id="funding-tab"
                    target="#funding"
                    ariaControls="funding"
                    ariaSelected={true}
                  />

                  {/* <SalesTabButton
                    title="Token Allocation"
                    className=""
                    id="token-tab"
                    target="#token"
                    ariaControls="token"
                    ariaSelected={false}
                  /> */}
                </ul>

                <div className="tab-content" id="myTabContent">
                  <SalesTabContent
                    className={"show active"}
                    id="funding"
                    ariaLabel="funding-tab"
                    title="JOUD"
                    description={sale.desc}
                    link="/"
                  />

                  {/* <SalesTabContent
                    className={""}
                    id="token"
                    ariaLabel="token-tab"
                    title="2 CNL = 0.0967 BTC"
                    description=" The World’s 1st ICO Platform That Offers Rewards and The
                    platform helps investors to make easy to purchase and
                    sell their tokens"
                    link="/"
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="text-white " style={{ marginTop: "54px" }}>
            <ul className="white-marker">

              {sale.dist.map((item, index) => <li key={index}> <BsChevronRight /> {item}</li>)}
              {/* <li>A small transaction fee of 1% will be charged on each JOUD token transaction. The transaction fees will be used to fund the continued development and maintenance of the Joud Taxi application</li>
              <li>As the adoption of the Joud Taxi application grows, the demand for JOUD tokens will increase. This will likely lead to an increase in the value of JOUD tokens on the market.</li>
              <li>To incentivize early adoption and usage of the Joud Taxi application, discounts and rewards may be offered to passengers who use JOUD tokens as a payment method for their rides.</li>
              <li>In addition to payment for rides, JOUD tokens may be used to purchase exclusive Joud Taxi merchandise or other products and services offered by the Joud Taxi ecosystem.</li>
              <li>Overall, the tokenomics of JOUD tokens are designed to encourage the growth and adoption of the Joud Taxi application while providing a valuable payment method for passengers. By leveraging the security and transparency of the blockchain, Joud Taxi aims to create a safe and secure environment for women and children to travel, and provide a convenient and reliable ride-hailing service.</li>
              <li>The tokens will be backed by shares of an SEC regulated company whose shares are traded on OTC. The JOUD token is secured by a reserve of gold bullion, providing investors with an underlying asset that supports the token's value.</li> */}
            </ul>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Sales;
