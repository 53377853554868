import React, { useEffect, useState, useMemo, memo } from "react";
import SlickSlider from "../SlickSlider/SlickSlider";
import WhyChooseUsItem from "./WhyChooseUsItem";
import {SlUserFemale} from 'react-icons/sl'
import {BiChild,BiCustomize} from 'react-icons/bi'
import {SiBinance} from 'react-icons/si'
import {BsChat} from 'react-icons/bs'
import {MdPayment,MdOutlineSpatialTracking} from 'react-icons/md'

import i18next from "i18next";
import { useTranslation } from "react-i18next";
const WhyChooseUs = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    rtl: i18next.language === 'ar' ? true : false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const { t } = useTranslation()
  
  const tem_slider_items  = useMemo(() => {

    const tempItems = t('whyChooseUs', {
      returnObjects: true
    }
    )
    // debugger;
   
  
    return tempItems;
  }, [i18next.language])

  const slider_items=useMemo(()=>{


    const tempItem2=tem_slider_items.slick


    const temp = [
      {
        src:         <SlUserFemale size={50} color='#E3F3F5'/> ,
        alt: "",
        link: "/",
        title: "Women-only drivers",
        description: "Joud Taxi will only employ women drivers, ensuring that passengers feel safe and comfortable during their ride.",
      },
      {
        src:         <BiChild size={50} color='#E3F3F5'/>,
        alt: "",
        link: "/",
        title: "Child-friendly",
        description: "Joud Taxi will provide special child seats for children to ensure their safety and comfort during the ride",
      },
      {
        src:           <SiBinance size={50} color='#E3F3F5'/>,
        alt: "",
        link: "/",
        title: <>Blockchain-based security</>,
        description: "The app will use blockchain technology to ensure the security of user data and transactions, as well as to prevent fraud and hacking.",
      },
      {
        src:         <BsChat size={50} color='#E3F3F5'/>,
        alt: "",
        link: "/",
        title: "In-app chat",
        description: "The app will include a chat feature that will allow passengers to communicate with their driver before and during the ride.",
      },
      {
        src:         <BiCustomize size={50} color='#E3F3F5'/>,
        alt: "",
        link: "/",
        title: "Customizable ride preferences",
        description: "Passengers will be able to specify their ride preferences, such as the temperature and music, to ensure a comfortable ride.",
      },
      {
        src:         <MdPayment size={50} color='#E3F3F5'/>,
        alt: "",
        link: "/",
        title: "Easy payment",
        description: "The app will allow passengers to easily pay for their ride using a variety of payment methods, including credit cards, debit cards, and the Joud Token.",
      },
      {
        src:         <MdOutlineSpatialTracking size={50} color='#E3F3F5'/>,
        alt: "",
        link: "/",
        title: "Real-time tracking",
        description: "Passengers will be able to track their driver in real-time, allowing them to know exactly when their driver will arrive.",
      },
    ];

    const retTemp=temp.map((item,index)=>(
      {
        ...item,
        title: tempItem2[index].title,
        description: tempItem2[index].description
      }
    ))

    return retTemp;

  },[tem_slider_items])
  
 

  

  const initalState = 0;
  const [count, setCount] = useState(initalState);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((old) => (old == 6 ? 0 : old + 1));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const toMultiply = 100 / slider_items.length;

  return (
    <section className="roadmap-area choose-area pb-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-50">
              <span className="sub-title">{tem_slider_items.whyChoose} </span>
              <h2 className="title" dangerouslySetInnerHTML={{__html: tem_slider_items.title}}>
                
              </h2>
            </div>
          </div>
        </div>

        <div className="row choose-active">
          <SlickSlider settings={slickSettings}>
            {slider_items.map((item, index) => (
              <div key={index} className="col-lg-3">
                <WhyChooseUsItem item={item} />
              </div>
            ))}
          </SlickSlider>
        </div>

        <div className="slide-progressbar">
          <div
            style={{
              // width: `${count * toMultiply}%`,
              backgroundSize: `${count * toMultiply}% 100%`,
            }}
            className="slide-filler"
          />
        </div>

        {/* <div
          className="slide-progress"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <span className="slider__label sr-only"></span>
        </div> */}
      </div>
    </section>
  );
};

export default memo(WhyChooseUs);
