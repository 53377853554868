import React from "react";
import { Link } from "react-router-dom";

const WhyChooseUsItem = (props) => {
  return (
    <div className="choose-item" style={{ background: 'linear-gradient(0deg, hsl(0deg 0% 100%) 0%, hsl(348deg 86% 98%) 1%, hsl(348deg 84% 96%) 4%, hsl(348deg 83% 94%) 7%, hsl(348deg 81% 92%) 11%, hsl(347deg 80% 90%) 16%, hsl(347deg 78% 88%) 23%, hsl(347deg 77% 86%) 33%, hsl(346deg 75% 84%) 47%, hsl(346deg 74% 82%) 81%)'}}>
      <div className="choose-icon">
        {/* <img src={props.item.src} alt={props.item.alt} /> */}
        {props.item.src}
      </div>
      <div className="choose-content">
        <h2 className="title text-dark">
          <Link to={props.item.link}>{props.item.title}</Link>
        </h2>
        <p className="text-muted">{props.item.description}</p>
      </div>
    </div>
  );
};

export default WhyChooseUsItem;
