import { useEffect } from 'react';

import React from 'react'
import { Link, NavLink, useLocation } from "react-router-dom";
import { CustomButton } from '../../lib/web3/CustomButton';
import { useState } from 'react';
import Buy from './Buy'
import BuyForm from './BuyForm';
import { BigNumber } from 'ethers'
import tokAbi from "../../constants/abi.json";
import tokAddress from "../../constants/address";
import { ethers } from "ethers";
import { useContractRead,usePrepareContractWrite,useContractWrite } from "wagmi";;

const SaleProgress = () => {

  const [presale,setPresale]=useState()

  const {data:presalId, isError:Error, isLoading:Loading } = useContractRead({
    address: tokAddress.tokenAddres,
    abi: tokAbi,
    functionName: 'presaleId',
   
  })
  const {data:presaleData, isError:Error2, isLoading:Loading2 } = useContractRead({
    address: tokAddress.tokenAddres,
    abi: tokAbi,
    functionName: 'presale',
    args:[presalId]
   
  })


  useEffect(()=>{
    setPresale(presaleData)
    console.log(presale)

  },[presaleData])


  return (<div id='ico-preale-div'>
     <h2 className="title d-flex justify-content-center">
            <span style={{color:'#ffa5d6'}}>ICO</span>  - Presale
              </h2>
    <div className='container d-flex justify-content-center'>
          <div className="banner-progress-wrap" style={{width:"80%"}}>
            
              {/* <ul>
                <li>Pre Sell</li>
                <li>Soft Cap</li>
                <li>Bonus</li>
              </ul> */}
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "0%" }}
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <h4 className="title">
                0% target raised <span>1 JOUD=$0.05</span>
              </h4>
              <h2 className="title d-flex  justify-content-center">
            First Stage Sold: {(+ethers.utils.formatEther(presale?.Sold??BigNumber.from("0"))).toFixed(1)}/{(+ethers.utils.formatEther(presale?.tokensToSell??BigNumber.from("0"))).toFixed(1)}
              </h2>
              <div className="title d-flex justify-content-center my-4 flex-column align-items-center">
              <CustomButton width={"261px"} />
              <Buy/>
              <BuyForm/>
              
            

                        
              </div>

            </div>
    </div>
  </div>
    
  )
}

export default SaleProgress
