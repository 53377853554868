import React, { useEffect, useState } from 'react';
import jsonp from 'jsonp';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import popupimage from '../../assets/img/logo/logo.png'
import './Popup.css';

const Popup = () => {
    const [formData, setFormData] = useState({
        FNAME: '',
        EMAIL: '',
        PHONE: ''
    });

    const [submissionStatus, setSubmissionStatus] = useState('');


    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const url = 'https://solutionsperfect.us14.list-manage.com/subscribe/post-json?u=12780e609f1b2c4350611d3f0&amp;id=ba1eb2262c&amp;f_id=00d907e0f0';
        jsonp(`${url}&EMAIL=${formData.EMAIL}&FNAME=${formData.FNAME}&PHONE=${formData.PHONE}`, { param: 'c' }, (_, data) => {
            const { msg, result } = data
            // do something with response
            setSubmissionStatus(msg);

            if (result === 'success') {
                setTimeout(() => {
                    setShowModal(false); // Close the popup after 3 seconds (adjust as needed)
                }, 3000);
            }
        });
    };

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowModal(true);
        }, 3000); // Adjust the delay time (in milliseconds) according to your needs

        return () => clearTimeout(timer);
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header>
                    {/* <Modal.Title>Modal Title</Modal.Title> */}
                    <button className="close-button" onClick={handleCloseModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {submissionStatus ? (
                        <div id='success-msg'>
                            <p>{submissionStatus}</p>
                        </div>
                    ) : (
                        <div id='popup-body' >
                            <div className="popup-img">
                                <img src={popupimage} alt='popupimg' />
                            </div>
                            <div id='popup-content'>
                                <h2>Unlock VIP Access <br /> - Join Joud Taxi's Exclusive Circle!</h2>
                                <p id='intro-txt'>Revolutionize transportation for kids and women!<br /> Enter your email now to receive exciting updates</p>
                                <form onSubmit={handleSubmit}>
                                    <input type="text" name="FNAME" placeholder='Enter your name' value={formData.FNAME} onChange={handleChange} />
                                    <input type="text" name="EMAIL" placeholder='Enter your email' value={formData.EMAIL} onChange={handleChange} />
                                    <input type="number" name="PHONE" placeholder='Enter your phone number' value={formData.PHONE} onChange={handleChange} />
                                    <input type="submit" value="Submit" id='btn' />
                                </form>
                                <p>Don't miss out on this thrilling journey – be part of the movement!</p>
                            </div>
                        </div>
                    )}

                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={handleCloseModal}>Close</Button>
                </Modal.Footer> */}
            </Modal>
        </div>
    );
};

export default Popup;

