import React from "react";

import { HiOutlineShieldExclamation, HiUserGroup } from 'react-icons/hi'
import { SlUserFemale } from 'react-icons/sl'
import WhyChooseUsTwoItem from "./UniqueFeatureItems";
import { useTranslation } from "react-i18next";


const UniqueFeatures = () => {

  const { t } = useTranslation()
  const temp_items = t('uniqueFeature', {
    returnObjects: true
  })
  const temp = [
    {
      src: <HiOutlineShieldExclamation size={50} color='black' />,
      title: "Emergency button",
      desc: "The app will include an emergency button that passengers can press in case of an emergency, immediately alerting Joud Taxi's emergency response team and providing their exact location",
      url: "/home-two",
    },
    {
      src: <SlUserFemale size={50} color='black' />,
      title: "Women's safety features",
      desc: "The app will provide additional safety features for women, such as the ability to share their ride details with a friend or family member and a panic button that will alert the police in case of danger",
      url: "/home-two",
    },
    {
      src: <HiUserGroup size={50} color='black' />,
      title: "Social responsibility",
      desc: "The Joud Taxi app will also emphasize social responsibility, by partnering with local charities and organizations that support women and children's causes. A portion of the profits generated by the app could be donated to these causes, helping to make a positive impact in the community",
      url: "/home-two",
    }
  ];

  const items = temp.map((item, index) => (
    {
      ...item,
      title: temp_items[index].title,
      desc: temp_items[index].desc
    }
  ))

  return (
    <section className="roadmap-area  pb-130 mt-10">
      <div className="container custom-container-four">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-70">
              {/* <span className="sub-title"></span> */}
              <h2 className="title" dangerouslySetInnerHTML={{ __html: t('unique') }}></h2>
            </div>
          </div>
        </div>

        <div className="row gy-5">
          {items.map((x, index) => (
            <div key={index} className="col-lg-4 col-sm-6">
              <WhyChooseUsTwoItem item={x} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default UniqueFeatures;
