import React, { useState } from "react";
import Banner from "../../components/Banner/Banner";
import ContactOne from "../../components/Contact/ContactOne";
import Roadmap from "../../components/Roadmap/Roadmap";
import Sales from "../../components/Sales/Sales";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import LayoutOne from "../../layouts/LayoutOne";
import UniqueFeatures from "../../components/UniqueFeatures/UniqueFeature";
import SaleProgress from "./../../components/Banner/SaleProgress";
import FooterOne from "../../components/Footer/FooterOne";
import Popup from "../../components/PopUp/Popup";
const Home = () => {

  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  return (
    <LayoutOne>
      <main className="fix">
      <Popup show={showPopup} handleClose={handleClosePopup} />

        <div className="banner-bg3 banner-area3 ">
          <Banner />
          <SaleProgress />
          <WhoWeAre />

          <WhyChooseUs />
          <Sales />
        </div>

        <div className="banner-bg2 banner-area2">
          <Roadmap />

          <UniqueFeatures />
          <ContactOne />
          <FooterOne />
        </div>

        {/* <TeamOne /> */}
      </main>
    </LayoutOne>
  );
};

export default Home;
